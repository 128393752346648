import React from "react";

import "./frame.scss";
import { Radius, Shadow, Color, Padding, Border } from "../types";

const defaultProps = {
  shadow: "none" as Shadow,
  radius: "none" as Radius,
  backgroundColor: "none",
  padding: "none" as Padding,
  border: "none" as Border,
};

export type Props = {
  /** The shadow to be applied to the frame. */
  shadow?: Shadow;
  /** The radius to be applied to the frame. */
  radius?: Radius;
  /** The children to be displayed in the frame. */
  children: React.ReactNode;
  /** The background color of the frame. */
  backgroundColor?: Color;
  /** The padding of the frame. */
  padding?: Padding;
  /** The border of the frame. */
  border?: Border;
};

const Frame = ({ shadow, children, radius, backgroundColor, padding, border }: Props): JSX.Element => {
  const shadowClass = shadow && shadow !== "none" ? `shadow-${shadow}` : "";
  const radiusClass = radius && radius !== "none" ? `gc-radius-${radius}` : "";
  const background = backgroundColor ? `gc-bg-color-${backgroundColor}` : "";
  const paddingClass = padding && padding !== "none" ? `frame-padding-${padding}` : "";
  const borderClass = border && border !== "none" ? `frame-border-${border}` : "";
  return (
    <div className={`conversight frame ${shadowClass} ${radiusClass} ${background} ${paddingClass} ${borderClass}`}>
      {children}
    </div>
  );
};

Frame.defaultProps = defaultProps;

export default Frame;
