import { KnowledgeType } from "../componentsTS/onboarding/trainingAgent/helper";
import Integrations from "../config/Integrations";
import { Provider } from "./BusinessApiNew";

export enum QuestionType {
  OpenQuestion = "text",
  FirstName = "first-name",
  LastName = "last-name",
  Phone = "phone-number",
  Email = "email",
}

export interface FormField {
  type: QuestionType;
  question: string;
  exampleAnswer?: string;
  description?: string;
  columnName?: string;
}

export type HoursSegment = {
  is_overnight: boolean;
  start: string;
  end: string;
  day: number;
};

export type SpecialHoursSegment = {
  is_overnight: boolean | null;
  start: string | null;
  end: string | null;
  date: string;
  is_closed: boolean | null;
};

export type HoursArray = Array<HoursSegment>;

type RegularHours = {
  open: HoursArray;
  hours_type: string;
};

export type OpenHoursKnowledge = {
  knowledgeType: KnowledgeType.OpenHours;
  provider: Provider;
  enabled: boolean;
  hours: Array<RegularHours>;
  additionalMessage: string;
  specialHours?: SpecialHoursSegment[];
  syncDisabled?: boolean;
  /** Unix timestamp of last open hours fetch from a provider */
  lastSynchronizedDate?: number;
};

// Type guards
export const isStandardQuestion = (question: Question): question is FormField => "question" in question;

export interface GoogleSheetsInfo {
  id: Integrations.GoogleSheets;
  spreadsheetId: string;
  sheetName: string;
  spreadsheetUrl: string;
  columns: Array<string>;
  enabled: boolean;
}

type IntegrationInfo = GoogleSheetsInfo;

export type Question = FormField;
export interface Form {
  id: string;
  name: string;
  enabled: boolean;
  dateAdded: number;
  questions: Array<Question>;
  integrations: Array<IntegrationInfo>;
  postFormAction?: PostFormAction;
}

/* Behavior Types
PULLED 8/9/23 */

// Types
export interface PostFormConnectBehavior extends ConnectBehavior {
  onNoAnswer?: AgentSaysBehavior;
}
export type PostFormAction = HangupBehavior | SendLinkBehavior | PostFormConnectBehavior;

export interface FormPayload {
  agentId: string;
  payload: Form;
}
export const enum BehaviorType {
  AgentSays = "agent-says",
  Connect = "connect",
  SendLink = "send-link",
  SendText = "send-text",
  TakeMessage = "take-message",
  YesNo = "yes-no",
  ContactForm = "contact-form",
  MultipleChoice = "multiple-choice",
  // Internal behaviors not configurable by users.
  Hangup = "hangup",
  Logic = "logic",
  CustomerLookup = "customer-lookup",
  KnowledgeBaseAnswer = "knowledge-base-answer",
}

export const behaviorDescriptionMap = {
  [BehaviorType.AgentSays]: "give a quick answer",
  [BehaviorType.Connect]: "transfer",
  [BehaviorType.SendLink]: "send a link",
  [BehaviorType.SendText]: "send a text",
  [BehaviorType.TakeMessage]: "take a message",
  [BehaviorType.YesNo]: "ask a yes/no question",
  [BehaviorType.ContactForm]: "ask a question",
  [BehaviorType.MultipleChoice]: "ask a multiple choice question",
  [BehaviorType.Hangup]: "end the conversation",
  [BehaviorType.Logic]: "use logic",
  [BehaviorType.CustomerLookup]: "look up customer",
  [BehaviorType.KnowledgeBaseAnswer]: "answer from knowledge base",
};

export type AgentSaysBehavior = {
  behaviorType: BehaviorType.AgentSays;
  behaviorSource?: BehaviorSource;
  message: string;
};

export const enum BehaviorSource {
  Skill = "skill",
  Contact = "contact",
}

export type ConnectBehavior = {
  behaviorType: BehaviorType.Connect;
  transferToContactId?: string | false;
  behaviorSource?: BehaviorSource;
  contactId?: string | false;
  skipOpenCheck?: boolean;
  message: string;
};

export type SendLinkBehavior = {
  behaviorType: BehaviorType.SendLink;
  behaviorSource?: BehaviorSource;
  message: string;
  link: string;
};

export enum SendTextTypes {
  PhoneNumber = "phone-number",
  Email = "email",
}

export type SendTextBehavior = {
  behaviorType: BehaviorType.SendText;
  behaviorSource?: BehaviorSource;
  type: SendTextTypes;
  message: string;
  value: string;
  contactId?: string;
};

export type TakeMessageBehavior = {
  behaviorType: BehaviorType.TakeMessage;
  behaviorSource?: BehaviorSource;
  contactId?: string | false;
  contactIdsToNotify?: Array<string>;
  message: string;
  integrations?: Array<{
    id: number;
    spreadsheetId: string;
  }>;
};

export enum LookupSource {
  Zapier = "zapier",
}

export interface MultiChoiceBehaviorChoice {
  choice: string;
  behavior: Behavior;
  id: string;
}

export interface ContactFormBehavior extends Omit<TakeMessageBehavior, "behaviorType"> {
  behaviorType: BehaviorType.ContactForm;
  // This not important for now, but we need it for the API
  formId: string;
}

export interface LogicBehavior {
  behaviorType: BehaviorType.Logic;
  logicId: string;
  message: string;
}
export type HangupBehavior = {
  behaviorType: BehaviorType.Hangup;
  behaviorSource?: BehaviorSource;
  message: string;
};

export type KnowledgeBaseAnswerBehavior = {
  behaviorType: BehaviorType.KnowledgeBaseAnswer;
  behaviorSource?: BehaviorSource;
  message: string;
  onNotFound: AgentSaysBehavior;
  documentId: string;
  documentName: string;
};

export type Behavior =
  | AgentSaysBehavior
  | ConnectBehavior
  | SendLinkBehavior
  | SendTextBehavior
  | TakeMessageBehavior
  | HangupBehavior
  | ContactFormBehavior
  | LogicBehavior
  | KnowledgeBaseAnswerBehavior;

export type CustomKnowledgePhrase = {
  seedPhrase: string;
  variants?: string[];
  action: "generate" | "regenerate";
};

export type KnowledgeCollisions = {
  message: string;
  skill: string;
};

export interface PaginationMeta {
  pageSize: number;
  currentPage: number;
  total: number;
}

// Knowledge Base types
export interface KnowledgeDocument {
  id: string;
  fileName: string;
  size: number;
  uploadDate: number;
  status: "ready" | "error" | "processing";
  error?: string;
  topic: string;
  source: string;
}

export interface DocumentData {
  documents: KnowledgeDocument[];
}
