import { GetAgentCustomersSearchParams } from "../api/AgentApiNew";
import { CallListParams } from "../api/CallsApiNew";

export const agentKeys = {
  // Key under which all agent data is stored.
  all: ["agents"],

  // Key under which data for a specific agent is stored.
  agent: ({ agentId }: { agentId: string }) => [...agentKeys.all, agentId],

  // Key under which data for an agent's
  business: ({ businessId }: { businessId: string }) => [businessId, "business-details"],
  // Key under which data for an agent's users is stored.
  agentUsers: ({ agentId }: { agentId: string }) => [...agentKeys.agent({ agentId }), "agent-users"],

  // Key under which an agent's open status is stored.
  isOpen: ({ agentId }: { agentId: string }) => [...agentKeys.all, agentId, "is-open"],

  // Key under which data for calls is stored.
  calls: ({ agentId, params }: { agentId: string; params: CallListParams }) => [
    ...agentKeys.all,
    agentId,
    "calls",
    params,
  ],

  // Key under which data for a specific call is stored.
  call: ({ agentId, callId }: { agentId: string; callId: string }) => [...agentKeys.all, agentId, "calls", callId],

  // Key under which data for customers is stored.
  customers: ({ agentId, params }: { agentId: string; params: GetAgentCustomersSearchParams }) => [
    ...agentKeys.all,
    agentId,
    "customers",
    params,
  ],

  // Key under which data for a specific customer is stored.
  customer: ({ agentId, customerId }: { agentId: string; customerId: string }) => [
    ...agentKeys.all,
    agentId,
    "customers",
    customerId,
  ],

  // Key under which data for a specific call is stored.
  callDetails: ({ agentId, callId }: { agentId: string; callId: string }) => [
    ...agentKeys.call({ agentId, callId }),
    "details",
  ],

  // Key under which data for a specific call is stored.
  callRecording: ({ agentId, callId }: { agentId: string; callId: string }) => [
    ...agentKeys.call({ agentId, callId }),
    "recording",
  ],

  // Key under which an agent's form data is stored.
  forms: ({ agentId }: { agentId: string }) => [...agentKeys.all, agentId, "forms"],

  // Key under which an agent's specific form data is stored.
  form: ({ agentId, formId }: { agentId: string; formId: string }) => [...agentKeys.forms({ agentId }), formId],

  // Key under which an agent's form data is stored.
  logics: ({ agentId }: { agentId: string }) => [...agentKeys.all, agentId, "logics"],

  // Key under which an agent's specific form data is stored.
  logic: ({ agentId, logicId }: { agentId: string; logicId: string }) => [...agentKeys.logics({ agentId }), logicId],

  // Key under which an agent's knowledge data is stored.
  knowledge: ({ agentId }: { agentId: string }) => [...agentKeys.agent({ agentId }), "knowledge"],

  // Key under which an agent's specific knowledge skill is stored.
  knowledgeSkill: ({ agentId, skill }: { agentId: string; skill: string }) => [
    ...agentKeys.knowledge({ agentId }),
    skill,
  ],

  // Key under which an agent's document data is stored.
  document: ({ agentId }: { agentId: string }) => [...agentKeys.all, agentId, "document"],

  documentDetails: ({ agentId, documentId }: { agentId: string; documentId: string }) => [
    ...agentKeys.all,
    agentId,
    "document",
    documentId,
  ],

  // key under which an agent's directory data is stored.
  directory: ({ agentId }: { agentId: string }) => [...agentKeys.agent({ agentId }), "directory"],

  directoryAll: ({ agentId }: { agentId: string }) => [...agentKeys.agent({ agentId }), "contacts-departments"],

  directoryDp: ({ agentId }: { agentId: string }) => [...agentKeys.agent({ agentId }), "directoryDepartment"],

  // key under which an agent's specific contact data is stored.
  directoryContact: ({ agentId, contactId }: { agentId: string; contactId: string }) => [
    ...agentKeys.directory({ agentId }),
    contactId,
  ],

  directoryDepartment: ({ agentId, departmentId }: { agentId: string; departmentId: string }) => [
    ...agentKeys.directory({ agentId }),
    departmentId,
  ],

  analytics: ({ agentId, analyticsId, fromDate = "" }: { agentId: string; analyticsId: string; fromDate: string }) => [
    ...agentKeys.agent({ agentId }),
    "analytics",
    analyticsId,
    fromDate,
  ],
};

export const userKeys = {
  // Key under which all user data is stored.
  all: ["users"],

  // Key under which data for a specific user is stored.
  user: ({ userId }: { userId: string }) => [...userKeys.all, userId],

  // Key under which the user's agents are stored.
  userAgents: ({ userId }: { userId: string }) => [...userKeys.user({ userId }), "agents"],

  // User Invitations
  userInvitations: ({ userId, invitationId }: { userId: string; invitationId: string }) => [
    ...userKeys.user({ userId }),
    "invitations",
    invitationId,
  ],
  // Key under which the user's upcoming invoice is stored.
  upcomingInvoice: ({ userId, action }: { userId: string; action?: string }) => [
    ...userKeys.user({ userId }),
    "upcoming-invoice",
    action || "default",
  ],

  // Key under which the user's upcoming invoice for a plan
  upcomingInvoiceForPlan: ({ userId, action, planId }: { userId: string; action?: string; planId: string }) => [
    ...userKeys.upcomingInvoice({ userId, action }),
    planId,
  ],

  // get team data
  teams: ({ userId }: { userId: string }) => [...userKeys.user({ userId }), "teams"],

  // Team member data
  userTeamMember: ({ userId, teamId, memberId }: { userId: string; teamId: string; memberId: string }) => [
    ...userKeys.teams({ userId }),
    teamId,
    "member",
    memberId,
  ],

  // Key under which the user's default payment method is stored.
  paymentMethod: ({ userId }: { userId: string }) => [...userKeys.user({ userId }), "payment-method"],

  // Key under which the user's stripe setup or payment secret is stored.
  stripeIntent: ({ userId, type }: { userId: string; type: "setup" | "payment" }) => [
    ...userKeys.user({ userId }),
    "stripe-intent",
    type,
  ],
};

export default { agentKeys, userKeys };
